/* 캘린더 */
.react-calendar {
    width: 100%;
    max-width: 328px;
    height: 400px;
    background: #ffff;
    border: none;
    padding: 8px 0;
}

.react-calendar__navigation button:disabled {
    background: #FFFFFF;
    color: #202020;
}

/* 달력 년/월 표시 글씨 커스텀 */
.react-calendar__navigation__label > span {
    font-size: 16px;
    font-weight: 500;
}

/*요일 section 커스텀 하기*/
.react-calendar__month-view__weekdays {

    /*월,화,수... 글자 부분*/

    & abbr {
        color: #444444;
        font-size: 18px;
        font-weight: 500;
    }
}

abbr[title] {
    text-decoration: none;
}

/*day 타일 한개 한개 모양 커스텀하기*/
.react-calendar__tile {
    font-size: 18px;
    font-weight: 400;
    width: 46px;
    height: 46px;
    color: #000000;
}

/* day 타일 hover, focus 시 모양 커스텀 */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #E96C12;
    border-radius: 999px;
    color: #FFFFFF;
}


/* 현재 날짜 */
.react-calendar__tile--now {
    background: #FFFFFF;
    color: #000000;
    border-radius: 999px;
}

/* 날짜 선택 됐을 때 day 타일 커스텀하기 */
.react-calendar__tile--active {
    background: #E96C12;
    color: #FFFFFF;
    border-radius: 999px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #E96C12;
}

/* 토,일 색상 변경 */
.react-calendar__month-view__days__day--weekend {
    color: #FF5E5E;
}

/* 날짜 이동 버튼 */
.react-calendar__navigation button {
    font-size: 20px;
    color: #202020;
}
